<template>
  <div class="big-data-container" v-loading="loading">
    <div class="content">
      <div class="content-input-box content-input-box__100">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/user">帐号管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <user-base :is-add="true" />
      </div>
    </div>
  </div>
</template>
<script>
import UserBase from "./detail-edit/user-base";
export default {
  components: {
    UserBase
  },
  data () {
    return {
      title: "添加帐号"
    }
  }
}
</script>